<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.bulletin") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <div
        class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
      >
        <div>
          <label class="block">{{ t("labels.estates") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span
              v-for="(estateId, index) in bulletin.estateIds"
              :key="estateId"
              >{{ getEstateName(estateId, estates)
              }}{{ index < bulletin.estateIds.length - 1 ? ", " : null }}</span
            >
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.groups") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span v-for="(roleId, index) in bulletin.roleIds" :key="roleId"
              >{{ t(`roles.${getRoleName(roleId, roles)}`)
              }}{{ index < bulletin.roleIds.length - 1 ? ", " : null }}</span
            >
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.date") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else>{{ getLocaleDate(locale, bulletin.createdAt) }}</span>
        </div>
        <div>
          <label class="block">{{ t("labels.sent") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else-if="!bulletin.sent || !bulletin.sent.length">{{
            t("labels.no")
          }}</span>
          <div v-else>
            <span v-for="(method, index) in bulletin.sent" :key="method"
              >{{ t(`messaging_methods.${method}`)
              }}{{ index < value.length - 1 ? ", " : null }}</span
            >
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.file") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <button
              v-if="bulletin.file"
              class="underline"
              @click="openFile(bulletin.file.path)"
            >
              {{ bulletin.file.name }}
            </button>
            <span v-else>{{ t("labels.no") }}</span>
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 md:grid-cols-2">
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <span v-if="waiting">
              <skeleton class="mb-2 h-5 w-full" />
            </span>
            <p v-else class="mb-2 text-lg font-semibold">
              {{ bulletin.topic }}
            </p>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ bulletin.content }}</span>
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div class="flex justify-end gap-2">
        <form-button
          :command="() => openModal()"
          :disabled="waiting"
          id="bulletin-send-button"
          label="send"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
        <form-button
          :command="() => router.push(`/bulletins/${bulletin.id}/edit`)"
          :disabled="waiting"
          id="bulletin-edit-button"
          label="edit"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
      </div>
    </div>
    <CustomDialog id="send-modal" :open="open">
      <div>
        <div class="flex items-center justify-between gap-4 pb-8">
          <h3>{{ t("headings.recipients") }}</h3>
          <XMarkIcon class="h-6 w-6 cursor-pointer" @click="closeModal()" />
        </div>
        <div>
          <custom-table
            :fields="fields"
            :items="items"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="lastName"
            :sortFields="sortFields"
            @row-click=""
          >
            <template #cell(lastName)="{ item }">
              {{ item.lastName }} {{ item.firstName }}
            </template>
            <template #cell(sendEmail)="{ item }">
              <FormCheckbox
                :disabled="
                  !item.email || !item.ecommunication || item.sentEmail
                "
                :id="`send-email-${item.id}-checkbox`"
                :invalid="false"
                :variant="company?.primary"
                v-model="item.sendEmail"
              />
            </template>
            <template #cell(sendSMS)="{ item }">
              <FormCheckbox
                :disabled="!item.phone || item.sentSMS"
                :id="`send-sms-${item.id}-checkbox`"
                :invalid="false"
                :variant="company?.primary"
                v-model="item.sendSMS"
              />
            </template>
            <template #cell(sendLetter)="{ item }">
              <FormCheckbox
                :disabled="item.sentLetter"
                :id="`send-letter-${item.id}-checkbox`"
                :invalid="false"
                :variant="company?.primary"
                v-model="item.sendLetter"
              />
            </template>
          </custom-table>
        </div>
        <hr class="my-3 border-slate-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => send()"
            :disabled="waiting"
            id="bulletin-send-button"
            label="send"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
    </CustomDialog>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomDialog from "../components/dialog/CustomDialog.vue";
import FormButton from "../components/form/FormButton.vue";
import FormCheckbox from "../components/form/FormCheckbox.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getEstateName } from "../utils/estateUtil";
import { getRoleName } from "../utils/roleUtils";

export default {
  components: {
    Breadcrumb,
    CustomDialog,
    CustomTable,
    FormButton,
    FormCheckbox,
    Skeleton,
    XMarkIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const bulletin = computed(() => store.state.bulletin.bulletin);
    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const file = computed(() => store.state.file.file);
    const roles = computed(() => store.state.role.roles);
    const user = computed(() => store.state.authentication.user);
    const users = computed(() => store.state.user.users);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.role.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const items = ref([]);

    const fields = ref([
      {
        key: "lastName",
        label: "name",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "sendEmail",
        label: "send_email",
      },
      {
        key: "sendSMS",
        label: "send_sms",
      },
      {
        key: "sendLetter",
        label: "send_letter",
      },
    ]);

    const searchFields = ref(["email", "lastName"]);
    const sortFields = ref(["email", "lastName"]);

    const recipients = ref([]);

    const open = ref(false);

    const closeModal = () => {
      open.value = false;
    };

    const openModal = () => {
      open.value = true;
    };

    const openFile = (path) => {
      store.dispatch("file/getFile", path);
    };

    const send = () => {
      const data = recipients.value.map((recipient) => {
        return {
          id: recipient.id,
          sendEmail: recipient.sendEmail,
          sendLetter: recipient.sendLetter,
          sendSMS: recipient.sendSMS,
          sentEmail: recipient.sentEmail,
          sentLetter: recipient.sentLetter,
          sentSMS: recipient.sentSMS,
        };
      });
      store.dispatch("bulletin/sendBulletin", {
        bulletinId: bulletin.value.id,
        recipients: data,
      });
    };

    onMounted(() => {
      store.dispatch("bulletin/getBulletin", route.params.bulletinId);
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", user.value.companyId);
      }
    });

    watch(
      () => file.value,
      (file) => {
        if (file) {
          window.open(file, "_blank");
        }
      }
    );

    watch(
      () => bulletin.value,
      (val) => {
        store.dispatch("user/getPossibleRecipients", {
          companyId: val.companyId,
          estateIds: val.estateIds,
          roleIds: val.roleIds,
        });
        recipients.value = val.recipients || [];
      }
    );

    watch(
      () => users.value,
      (val) => {
        items.value = [];
        for (const user of val) {
          const index = recipients.value.findIndex(
            (recipient) => recipient.id === user.id
          );
          if (index >= 0) {
            const recipient = {
              ...user,
              ...recipients.value[index],
              sendEmail: recipients.value[index].sentEmail,
              sendLetter: recipients.value[index].sentLetter,
              sendSMS: recipients.value[index].sentSMS,
            };
            recipients.value.splice(index, 1, recipient);
          } else {
            recipients.value.push({
              ...user,
              sendEmail: false,
              sendLetter: false,
              sendSMS: false,
            });
          }
        }
        items.value = recipients.value;
      }
    );

    return {
      bulletin,
      closeModal,
      company,
      estates,
      fields,
      getEstateName,
      getLocaleDate,
      getRoleName,
      items,
      locale,
      open,
      openFile,
      openModal,
      roles,
      router,
      searchFields,
      send,
      sortFields,
      t,
      waiting,
      XMarkIcon,
    };
  },
};
</script>
