<template>
  <div>
    <dialog :id="id" class="m-auto rounded-lg p-4 shadow-md">
      <slot></slot>
    </dialog>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    open: {
      type: Boolean,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const dialog = ref();

    onMounted(() => {
      dialog.value = document.querySelector("dialog");
    });

    watch(
      () => props.open,
      (open) => {
        if (open) {
          dialog.value.showModal();
        } else {
          dialog.value.close();
        }
      }
    );

    return {
      t,
    };
  },
};
</script>
